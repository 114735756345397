export default [
	{
		id: "BTC",
		color: "hsl(173, 70%, 50%)",
		data: [
			{
				x: "SUN",
				y: 183
			},
			{
				x: "MON",
				y: 201
			},
			{
				x: "TUES",
				y: 291
			},
			{
				x: "WED",
				y: 262
			},
			{
				x: "THU",
				y: 163
			},
			{
				x: "FRI",
				y: 63
			},
			{
				x: "SAT",
				y: 86
			}
		]
	},
	{
		id: "ETH",
		color: "hsl(325, 70%, 50%)",
		data: [
			{
				x: "SUN",
				y: 256
			},
			{
				x: "MON",
				y: 113
			},
			{
				x: "TUES",
				y: 183
			},
			{
				x: "WED",
				y: 148
			},
			{
				x: "THU",
				y: 119
			},
			{
				x: "FRI",
				y: 261
			},
			{
				x: "SAT",
				y: 19
			}
		]
	},
	{
		id: "APE",
		color: "hsl(142, 70%, 50%)",
		data: [
			{
				x: "SUN",
				y: 111
			},
			{
				x: "MON",
				y: 93
			},
			{
				x: "TUES",
				y: 210
			},
			{
				x: "WED",
				y: 103
			},
			{
				x: "THU",
				y: 75
			},
			{
				x: "FRI",
				y: 171
			},
			{
				x: "SAT",
				y: 171
			}
		]
	}
];
