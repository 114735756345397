import React from "react";

const Home = () => {
	return (
		<>
			<div className="h-[calc(100vh-100px)] w-[80%] mx-auto flex flex-col items-center justify-center text-center">
				<h1 className="text-3xl font-bold mb-4">
					Crypto Tracker
				</h1>
				<p className="text-neutral-300 mb-8">
					We offer a range of crypto services, including buying and selling cryptocurrencies, secure wallet storage, and educational resources.
				</p>
				<div className="w-full mb-8 flex justify-around">
					<a href="/login" className="px-8 py-2 font-bold bg-blue-500 hover:bg-blue-700 rounded-full">
						Sign In
					</a>
					<a href="/signup" className="px-8 py-2 font-bold bg-green-500 hover:bg-green-700 rounded-full">
						Sign Up
					</a>
				</div>
				<a href="/wallets" className="px-4 py-2 font-bold bg-gray-700 hover:bg-gray-800 rounded-full">
					View Wallet Options
				</a>
			</div>
		</>
	);
};

export default Home;
