import React, { useState } from "react";
import placeholder from "../assets/avatar.png";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Link from "./Link";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../utils/firebase";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";

const NavDrawer = () => {
	const ver = "v0.1.3";
	const [user] = useAuthState(auth);
	// !! Add click out outside of menu and escape button to close.
	const [isNavOpen, setIsNavOpen] = useState(false);
	const navigate = useNavigate();

	const escFunction = (e) => {
		if (e.key === "Escape")
			setIsNavOpen(false);
	};
	document.addEventListener("keydown", escFunction, false);

	const logout = async () => {
		await signOut(auth);
		navigate("/");
	};

	return (
		<div className="flex flex-row gap-4 items-center">
			<button
				onClick={() => setIsNavOpen((prev) => !prev) || escFunction}
				className="hover:outline outline-white rounded-full"
			>
				<img src={user.photoURL ? user.photoURL : placeholder} alt="Avatar" className="h-12 w-12 p-0.5 object-cover bg-blue-800 rounded-full shadow-inner" />
			</button>
			<div id="navDrawer" className={`${isNavOpen ? "translate-x-0" : "translate-x-full"} ease-in duration-150
				flex flex-col justify-between z-50 absolute top-0 right-0 bottom-0 lg:left-[75%] left-1/4 bg-neutral-800`}
			>
				<div>
					<div className="relative p-1 flex flex-row gap-2 items-center bg-primary border-0">
						<img src={user.photoURL ? user.photoURL : placeholder} alt="Avatar" className="h-12 w-12 p-0.5 object-cover bg-neutral-900 rounded-full" />
						<div className="flex flex-col justify-center">
							<h3 className="flex flex-row gap-1 items-center text-xl font-bold leading-tight">
								{/* TODO: Add user role badges for "premium" & "admin" */}
								{user.displayName}
							</h3>
							<p className="text-sm text-neutral-400">
								{user.email}
							</p>
						</div>
						<button
							className="absolute top-1/2 right-4 -translate-y-1/2"
							onClick={() => setIsNavOpen((prev) => !prev) || escFunction}
						>
							<XMarkIcon className="w-8 h-8" />
						</button>
					</div>
					<div className="flex flex-col">
						<Link
							name="Home"
							link="/"
						/>
						<Link
							name="Dashboard"
							link="/dashboard"
						/>
						<Link
							name="Settings"
							link="/account"
						/>
					</div>
				</div>
				<div>
					<button onClick={logout} className="w-full py-4 bg-blue-500 hover:bg-blue-600 transition-colors">
						Logout
					</button>
					<div className="py-1 px-2 flex flex-row justify-between bg-neutral-900 text-sm">
						{ver}
						<div className="flex flex-row gap-2">
							<a href="https://nanos.club/tos" target="norel noopen" className="hyperlink">Terms</a>
							<a href="https://nanos.club/privacy" target="norel noopen" className="hyperlink">Privacy</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NavDrawer;
