import React from "react";
import { useLocation } from "react-router-dom";

const Link = ({ name, link }) => {
	const location = useLocation();

	return (
		<a href={location.pathname === link ? null : link} className={`${location.pathname === link ? "bg-neutral-900 border-l-[3px] border-blue-700 cursor-pointer" : "hover:bg-neutral-900 border-l hover:border-l-[3px] border-neutral-500"} p-2 text-center text-lg transition-all duration-200`}>
			{name}
		</a>
	);
};

export default Link;
