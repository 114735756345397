import React, { useEffect, useState } from "react";
import { ArrowsUpDownIcon, ArrowDownCircleIcon, ArrowUpCircleIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";

const formatCurrency = (num) => {
	return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const formatVolume = (num) => {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const formatPercent = (num) => {
	return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "%";
};

const CryptoListener = ({ id }) => {
	if (!id) {
		return (
			<div className="flex justify-center items-center p-2 bg-neutral-800 border border-neutral-700 rounded-md">
				<div className="w-full bg-primary p-1 text-center rounded">
					<p className="text-neutral-400 animate-pulse">
						No ID
					</p>
				</div>
			</div>
		);
	}

	const [data, setData] = useState();
	const [ticker, setTicker] = useState();
	const [rate, setRate] = useState();

	const fetchAPI = async () => {
		const responseData = await fetch(`https://api.exchange.coinbase.com/currencies/${id}`);
		const jsonData = await responseData.json();
		setData(jsonData);
		const responseTicker = await fetch(`https://api.exchange.coinbase.com/products/${id}-USD/ticker`);
		const jsonTicker = await responseTicker.json();
		setTicker(jsonTicker);
		const responseRate = await fetch(`https://api.pro.coinbase.com/products/${id}-USD/stats`);
		const jsonRate = await responseRate.json();
		setRate(jsonRate);
	};

	useEffect(() => {
		fetchAPI();
		const interval = setInterval((async () => {
			try {
				await fetchAPI();
			} catch (err) {
				console.error(err);
			}
		}), 3000);

		return () => clearInterval(interval);
	}, [id]);

	if (!data || !ticker || !rate) {
		return (
			<div className="p-2 flex justify-center items-center bg-neutral-800 border border-neutral-700 rounded-md">
				<p className="animate-pulse">
					Loading...
				</p>
			</div>
		);
	}

	let cryptoName = data.name;
	let cryptoID = data.id;
	let cryptoPrice = JSON.parse(ticker.price);
	let volumeRate = JSON.parse(rate.volume);
	let volumeUSD = rate.volume * ticker.price;
	let volumeRate30 = JSON.parse(rate.volume_30day);
	let volumeUSD30 = rate.volume_30day * ticker.price;
	let changeRate = (rate.last - rate.open) / rate.open * 100;

	return (
		<>
			<div className="relative p-2 bg-neutral-800 border border-neutral-700 rounded-md">
				<StarIcon className="absolute top-2 left-2 w-5 h-5 fill-orange-300" />
				<div className="flex flex-col justify-center items-center">
					<h3 className="flex flex-row gap-1 items-center text-xl">
						{cryptoName ? cryptoName : "Loading..."} <span className="text-sm text-neutral-400">({cryptoID ? cryptoID : "..."})</span>
					</h3>
					<div className="w-full p-1 flex flex-row justify-evenly items-center bg-primary rounded">
						<p className="text-orange-400">
							{cryptoPrice ? formatCurrency(cryptoPrice) : "--.--"}
						</p>
						<div className="flex flex-row gap-1 items-center">
							<ArrowDownCircleIcon className={`${changeRate < 0 ? "stroke-red-500" : "hidden"} w-5 h-5`} />
							<ArrowUpCircleIcon className={`${changeRate > 0 ? "stroke-green-500" : "hidden"} w-5 h-5`} />
							<p className={`${changeRate < 0 ? "text-red-500" : "text-green-500"}`}>
								{changeRate ? formatPercent(changeRate) : "--.--%"}
							</p>
						</div>
					</div>
					<div className="w-full mt-2 grid grid-rows-2 xl:grid-cols-2 xl:grid-rows-none gap-2">
						<div className="p-2 flex flex-col items-center bg-primary rounded">
							<p className="flex flex-row gap-1 items-center">
								Traded <span className="text-sm text-neutral-400">(24hr)</span>
							</p>
							<span className="text-orange-400">
								{volumeRate ? formatVolume(volumeRate) : "--.--"} {cryptoID ? cryptoID : "..."}
							</span>
							<ArrowsUpDownIcon className="stroke-neutral-400 w-4 h-4" />
							<span className="text-orange-400">
								{volumeUSD ? formatCurrency(volumeUSD) : "--.--"}
							</span>
						</div>
						<div className="p-2 flex flex-col items-center bg-primary rounded">
							<p className="flex flex-row gap-1 items-center">
								Traded <span className="text-sm text-neutral-400">(30d)</span>
							</p>
							<span className="text-orange-400">
								{volumeRate30 ? formatVolume(volumeRate30) : "--.--"} {cryptoID ? cryptoID : "..."}
							</span>
							<ArrowsUpDownIcon className="stroke-neutral-400 w-4 h-4" />
							<span className="text-orange-400">
								{volumeUSD30 ? formatCurrency(volumeUSD30) : "--.--"}
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CryptoListener;
