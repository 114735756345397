import React from "react";
import { WalletIcon } from "@heroicons/react/24/solid";
import { auth } from "../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const formatCurrency = (num) => {
	return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const formatCrypto = (num) => {
	return num.toFixed(6).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1");
};

const DashboardHeader = () => {
	// use `loading` with user for any loading state
	const [user] = useAuthState(auth);
	let userBalanceUSD = formatCurrency(0);
	let userBalanceETH = formatCrypto(0);
	let userBalanceBTC = formatCrypto(0);

	return (
		<div className="flex flex-col p-2 bg-neutral-800 border border-neutral-700 rounded-md">
			<div className="h-full mb-2 lg:mb-0 flex justify-center items-center">
				<h2 className="text-center text-2xl">
					Hello, <span className="text-blue-500 font-semibold">{user.displayName ? user.displayName : "User"}</span>.
				</h2>
			</div>
			<div className="flex flex-col">
				<div
					className="relative p-1 flex flex-row items-center justify-center lg:justify-start bg-primary rounded">
					<WalletIcon className="absolute w-6 h-6 left-1 p-1 bg-neutral-800 rounded-lg" />
					<p className="text-white-400 lg:ml-8">
						{userBalanceUSD}
					</p>
				</div>
				<div className="mt-2 flex flex-col lg:flex-row gap-2">
					<div className="lg:flex-[50%] relative p-1 flex flex-row items-center justify-center lg:justify-start bg-primary rounded">
						<svg className="absolute w-6 h-6 fill-white left-1 p-1 bg-neutral-800 rounded-lg" viewBox="0 0 320 512">
							<path d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z" />
						</svg>
						<p className="text-white-400 lg:ml-8">
							{userBalanceETH}
						</p>
					</div>
					<div className="lg:flex-[50%] relative p-1 flex flex-row items-center justify-center lg:justify-start bg-primary rounded">
						<svg className="absolute w-6 h-6 fill-white left-1 p-1 bg-neutral-800 rounded-lg" viewBox="0 0 512 512">
							<path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zm-141.651-35.33c4.937-32.999-20.191-50.739-54.55-62.573l11.146-44.702-27.213-6.781-10.851 43.524c-7.154-1.783-14.502-3.464-21.803-5.13l10.929-43.81-27.198-6.781-11.153 44.686c-5.922-1.349-11.735-2.682-17.377-4.084l.031-.14-37.53-9.37-7.239 29.062s20.191 4.627 19.765 4.913c11.022 2.751 13.014 10.044 12.68 15.825l-12.696 50.925c.76.194 1.744.473 2.829.907-.907-.225-1.876-.473-2.876-.713l-17.796 71.338c-1.349 3.348-4.767 8.37-12.471 6.464.271.395-19.78-4.937-19.78-4.937l-13.51 31.147 35.414 8.827c6.588 1.651 13.045 3.379 19.4 5.006l-11.262 45.213 27.182 6.781 11.153-44.733a1038.209 1038.209 0 0 0 21.687 5.627l-11.115 44.523 27.213 6.781 11.262-45.128c46.404 8.781 81.299 5.239 95.986-36.727 11.836-33.79-.589-53.281-25.004-65.991 17.78-4.098 31.174-15.792 34.747-39.949zm-62.177 87.179c-8.41 33.79-65.308 15.523-83.755 10.943l14.944-59.899c18.446 4.603 77.6 13.717 68.811 48.956zm8.417-87.667c-7.673 30.736-55.031 15.12-70.393 11.292l13.548-54.327c15.363 3.828 64.836 10.973 56.845 43.035z" />
						</svg>
						<p className="text-white-400 lg:ml-8">
							{userBalanceBTC}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashboardHeader;
