import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

const Error = ({ message }) => {
	return (
		<div className="p-2 px-3 flex flex-row justify-between backdrop-blur-sm border border-red-500 rounded-lg shadow-md transition-all duration-200">
			<div className="flex flex-row gap-3 items-center">
				<ExclamationCircleIcon className="stroke-red-500 w-6 h-6" />
				<p className="text-lg">
					{message}
				</p>
			</div>
		</div>
	);
};

export default Error;
