import React from "react";
import NavDrawer from "./NavDrawer";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../utils/firebase";

const Navigation = () => {
	const [user] = useAuthState(auth);

	return (
		<nav className="flex flex-row justify-between bg-blue-700">
			<a
				href="/" id="navHeader"
				className="p-2 px-3 flex justify-center items-center bg-blue-800 hover:bg-blue-900 transition-colors"
			>
				<h1 className="lg:block hidden text-2xl font-semibold">
					Crypto Tracker
				</h1>
				<h1 className="lg:hidden block text-2xl font-semibold">
					Tracker
				</h1>
			</a>
			<div className="p-1 mr-1 flex justify-end">
				{user && (
					<NavDrawer />
				)}
				{!user && (
					<div className="p-1 flex flex-row gap-2 items-center">
						<a className="p-2 bg-blue-800 hover:bg-blue-900 rounded-md transition-colors drop-shadow" href={"/login"}>
							Login
						</a>
						<p>
							or
						</p>
						<a className="p-2 bg-blue-800 hover:bg-blue-900 rounded-md transition-colors drop-shadow" href={"/signup"}>
							Sign up
						</a>
					</div>
				)}
			</div>
		</nav>
	);
};

export default Navigation;
