import React from "react";
import { Helmet } from "react-helmet";

const NotFound = () => {
	return (
		<>
			<Helmet>
				<title>Page Not Found</title>
			</Helmet>
			<div className="flex flex-col items-center mt-24">
				<div className="w-[80vw] md:w-[50vw] py-6 px-12 flex flex-row gap-3 justify-center items-center bg-neutral-800 border border-red-500 rounded-lg">
					<h1 className="text-3xl font-bold">
						404
					</h1>
					<span className="h-full bg-white w-0.5 text-transparent">/</span>
					<p className="text-neutral-400 text-xl font-semibold">
						page not found
					</p>
				</div>
				<a className="mt-6 text-xl hover:text-neutral-200 underline underline-offset-2" href="/">
					Go back
				</a>
			</div>
		</>
	);
};

export default NotFound;
