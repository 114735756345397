import React, { useState } from "react";
import { auth, updateUserData } from "../../utils/firebase";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { Helmet } from "react-helmet";

export default function Register() {
	// use `loading` with user for any loading state
	const [user] = useAuthState(auth);
	const navigate = useNavigate();
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const register = async () => {
		navigate("/dashboard");
		try {
			await createUserWithEmailAndPassword(auth, email, password);
			await updateProfile(user, { displayName: username });
			console.log(updateProfile);
			console.log(user);
			console.log(user.displayName, username);
			await updateUserData(user);
		} catch (error) {
			console.error(error.message);
		}
	};

	if (user)
		navigate("/dashboard");

	return (
		<>
			<Helmet>
				<title>Crypto Tracker | Sign up</title>
			</Helmet>
			<div className="mt-24 flex justify-center items-center">
				<div className="w-[90%] lg:w-fit lg:min-w-[400px] flex flex-col gap-4 bg-neutral-800 border border-neutral-700 rounded-xl overflow-hidden">
					<div className="relative h-40 bg-blue-700">
						<svg className="absolute bottom-0 fill-neutral-800" viewBox="0 0 1440 320">
							<path d="M0,64L48,80C96,96,192,128,288,128C384,128,480,96,576,85.3C672,75,768,85,864,122.7C960,160,1056,224,1152,245.3C1248,267,1344,245,1392,234.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
						</svg>
					</div>
					<h1 className="p-1 text-center text-3xl font-bold rounded">
						Sign up
					</h1>
					<div className="p-4 flex flex-col gap-4 rounded">
						{/* TODO: Make submit buttons work and don't allow submit if required fields aren't filled. */}
						<form
							onSubmit={register}
							className="flex flex-col gap-4"
						>
							<div className="flex flex-col gap-10 mb-4">
								<div className="relative">
									<input required
										className="peer w-full py-2 px-1 bg-transparent border-0 border-b-2 leading-tight focus:border-blue-500 focus:ring-0 placeholder-transparent"
										type="text" name="username" id="username" value={username} placeholder="Username"
										onChange={(e) => {
											setUsername(e.target.value);
										}}
									/>
									<label htmlFor="username" className="pointer-events-none absolute left-0 -top-3.5 font-semibold text-gray-300 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm">
										Username
									</label>
								</div>
								<div className="relative">
									<input required
										className="peer w-full py-2 px-1 bg-transparent border-0 border-b-2 leading-tight focus:border-blue-500 focus:ring-0 placeholder-transparent"
										type="email" name="email" id="email" value={email} placeholder="Email"
										onChange={(e) => {
											setEmail(e.target.value);
										}}
									/>
									<label htmlFor="email" className="pointer-events-none absolute left-0 -top-3.5 font-semibold text-gray-300 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm">
										Email
									</label>
								</div>
								<div className="relative">
									<input required
										className="peer w-full py-2 px-1 bg-transparent border-0 border-b-2 leading-tight focus:border-blue-500 focus:ring-0 placeholder-transparent"
										type="password" name="password" id="password" value={password} placeholder="Password"
										onChange={(e) => {
											setPassword(e.target.value);
										}}
									/>
									<label htmlFor="password" className="pointer-events-none absolute left-0 -top-3.5 font-semibold text-gray-300 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm">
										Password
									</label>
								</div>
							</div>
							<div className="flex flex-row gap-1 items-center">
								<input required
									className="rounded focus:border-blue-500 focus:ring-blue-500 focus:ring-0"
									type="checkbox" name="terms" id="terms"
								/>
								<label htmlFor="terms" className="text-sm font-bold">
									I agree to the {" "}
									<a href="https://nanos.club/tos" target="norel noopen" className="hyperlink">
									Terms
									</a> & <a href="https://nanos.club/privacy" target="norel noopen" className="hyperlink">
									Privacy Policy
									</a>.
								</label>
							</div>
							<button onClick={register} className="p-2 bg-blue-500 hover:bg-blue-600 rounded" type="submit" value="submit">
								Register
							</button>
						</form>
						<p className="text-center">
							Already have an account? <a className="hyperlink" href="/login">Login</a>
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
