import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, updateProfile } from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getStorage, getDownloadURL, ref, uploadBytes } from "firebase/storage";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
/* TODO: Create different account roles: default, premium, admin */
export const fs = getFirestore(app);
export const db = getStorage();

// Analytics
const analytics = getAnalytics(app);

logEvent(analytics, "login");
logEvent(analytics, "sign_up");
logEvent(analytics, "ad_impression");

// User data
export async function updateUserData(user) {
	const docRef = await addDoc(collection(fs, "users"), {
		username: user.displayName,
		email: user.email,
		roles: ["default"]
	});
	console.log("Wrote to: ", docRef.id);
}

// Storage function
const imagesRef = ref(db, "users/avatar");

export async function upload(file, user, setLoading) {
	const fileRef = ref(imagesRef, user.uid + ".png");
	setLoading(true);

	const snapshot = await uploadBytes(fileRef, file);
	console.log(snapshot);
	const photoURL = await getDownloadURL(fileRef);

	updateProfile(user, { photoURL });

	setLoading(false);
	console.log("succeeded");
}
