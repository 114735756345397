import React, { useEffect, useState } from "react";
import { useAuthState, useSendPasswordResetEmail, useSendEmailVerification } from "react-firebase-hooks/auth";
import { auth, upload } from "../../utils/firebase";
import { Helmet } from "react-helmet";
import { ChevronLeftIcon, CheckBadgeIcon, CameraIcon, PencilSquareIcon, XMarkIcon, ShieldCheckIcon } from "@heroicons/react/24/solid";
import toast, { Toaster } from "react-hot-toast";
import Error from "../../components/toasts/Error";
import Success from "../../components/toasts/Success";
import { updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Account = () => {
	const [user] = useAuthState(auth);
	const navigate = useNavigate();
	const [photo, setPhoto] = useState(null);
	const [photoURL, setPhotoURL] = useState("../assets/avatar.png");
	const [loading, setLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [username, setUsername] = useState("");

	// Toast messages
	const successUpload = () => toast.custom(() => (
		<Success message="Profile picture updated" />
	));

	const failedUpload = () => toast.custom(() => (
		<Error message="This file type is not supported or is too large. Supported file types: png, jpg, jpeg, gif" />
	));

	const successLink = () => toast.custom(() => (
		<Success message="Password reset link sent." />
	));

	const failedLink = () => toast.custom(() => (
		<Error message="Could not send password reset link." />
	));

	const successVerify = () => toast.custom(() => (
		<Success message="Verification link sent." />
	));

	const failedVerify = () => toast.custom(() => (
		<Error message="Could not send verification link." />
	));

	// Handle profile picture updating
	const handleChange = (e) => {
		if (e.target.files[0])
			setPhoto(e.target.files[0]);
	};

	const handleUpload = async () => {
		try {
			await upload(photo, user, setLoading);
			successUpload();
		} catch (err) {
			console.error(err);
			setLoading(false);
			failedUpload();
		}
	};

	useEffect(() => {
		if (user ? user.photoURL : null)
			setPhotoURL(user.photoURL);
	}, [user]);

	// Updating profile information
	const resetPassword = async () => {
		try {
			useSendPasswordResetEmail(auth);
			successLink();
		} catch (err) {
			failedLink();
			console.error(err);
		}
	};

	const verifyEmail = async () => {
		try {
			useSendEmailVerification(auth);
			successVerify();
		} catch (err) {
			failedVerify();
			console.error(err);
		}
	};

	const changeUsername = async () => {
		try {
			await updateProfile(user, { displayName: username });
			setIsModalOpen(false);
		} catch (err) {
			console.error(err);
		}
	};

	if (!user)
		navigate("/login");

	if (user) {
		return (
			<>
				<Helmet>
					<title>Account Settings</title>
				</Helmet>
				<div className={`z-50 absolute ${loading ? "block" : "hidden"} inset-0 bg-neutral-800/10 backdrop-blur`}>
					<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
						<p className="animate-pulse">
							Loading
						</p>
					</div>
				</div>
				<div className="relative m-2 p-2 flex flex-col items-center justify-center bg-neutral-800 border border-neutral-700 rounded-md">
					<a href="/dashboard" className="group absolute top-3 left-2 flex flex-row gap-1 items-center">
						<ChevronLeftIcon className="w-5 h-5 fill-blue-500 group-hover:fill-blue-600" />
						<span className="text-blue-500 group-hover:text-blue-600 uppercase text-sm font-semibold">Back to Dashboard</span>
					</a>
					<h1 className="mt-10 lg:my-4 text-center font-bold text-lg">
						Account Settings
					</h1>
					<div className="flex flex-col gap-4">
						<div className="p-2 flex flex-row gap-4 items-center">
							<div className="flex flex-col gap-2">
								<label htmlFor="file" className="group relative outline outline-white cursor-pointer rounded-full overflow-hidden">
									{/* TODO: Change user roles "premium" & "admin" to have a hexagon profile picture outline */}
									<img src={photoURL} alt="Avatar" className="w-24 h-24 object-cover rounded-full" />
									<div className="absolute p-1 bottom-0 left-0 right-0 bg-primary/70 flex justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-100">
										<CameraIcon className="w-6 h-6" />
									</div>
								</label>
								<button
									className={`${loading || !photo ? "hidden bg-neutral-400" : "bg-blue-500"} rounded`}
									onClick={handleUpload} disabled={loading || !photo}
								>
									Save
								</button>
								<input className="hidden" onChange={handleChange} type="file" id="file" name="file" />
							</div>
							<div className="flex flex-col justify-center">
								<p className="flex flex-row gap-2 items-center text-2xl font-bold leading-tight">
									{user.displayName}
									<button
										className="bg-blue-500 hover:bg-blue-600 p-0.5 rounded"
										onClick={() => setIsModalOpen((prev) => !prev)}
									>
										<PencilSquareIcon className="w-4 h-4" />
									</button>
								</p>
								<div className="flex flex-row gap-1 items-center text-neutral-400">
									{user.emailVerified &&
										<div className="flex flex-row gap-1">
											<p>{user.email}</p>
											<CheckBadgeIcon className="fill-blue-500 w-5 h-5" title="Verified" />
										</div>
									}
									{!user.emailVerified &&
										<div className="flex flex-col">
											<div className="flex flex-row gap-1">
												<p>{user.email}</p>
												<CheckBadgeIcon className="fill-neutral-400 w-5 h-5" title="Not Verified" />
											</div>
											<button onClick={verifyEmail} className="w-fit hyperlink">Verify Email</button>
										</div>
									}
								</div>
							</div>
							<Toaster position="bottom-center" />
						</div>
						<div className="flex flex-col gap-2">
							<div className="p-2 grid grid-cols-2 bg-primary rounded">
								<p>
									Password
								</p>
								<div>
									<button
										className="hyperlink"
										onClick={resetPassword}
									>
										Change Password
									</button>
								</div>
							</div>
							<div className="p-2 grid grid-cols-2 bg-primary rounded">
								<p>
									Phone
								</p>
								<p>
									{user.phoneNumber}
									{!user.phoneNumber &&
										<a href={null} className="hyperlink">Add/change phone number</a>
									}
								</p>
							</div>
							<div className="p-2 grid grid-cols-2 bg-primary rounded">
								<p>timezone</p>
								<p>* user timezone</p>
							</div>
							<div className="p-2 grid grid-cols-2 items-center bg-primary rounded">
								<p>Account Type</p>
								<div className="flex flex-col text-xl">
									<p>
										regular
									</p>
									<p className="flex flex-row gap-1 items-center">
										premium <CheckBadgeIcon className="fill-blue-700 w-6 h-6" />
									</p>
									<p className="flex flex-row gap-1 items-center">
										admin <ShieldCheckIcon className="fill-yellow-500 w-6 h-6" />
									</p>
								</div>
							</div>
							<p className="text-center text-neutral-500">
								Created <span className="font-semibold text-neutral-400">{user.metadata.creationTime}</span>
							</p>
						</div>
					</div>
				</div>
				{/* change name modal */}
				<div className={`${isModalOpen ? "block" : "hidden"} absolute inset-0 backdrop-blur-sm flex flex-col justify-between z-50`}>
					<div className="relative mx-auto mt-[12vh] w-[400px] h-[200px] flex justify-center items-center bg-neutral-800 border border-neutral-700 rounded-md">
						<button
							className="absolute top-2 right-2"
							onClick={() => setIsModalOpen((prev) => !prev)}
						>
							<XMarkIcon className="w-8 h-8" />
						</button>
						<div className="relative">
							<input required
								className="peer w-full py-2 px-1 bg-transparent border-0 border-b-2 leading-tight focus:outline-0 focus:border-blue-500 focus:ring-0 placeholder-transparent"
								type="input" name="username" id="username" value={username} placeholder="Username"
								onChange={(e) => {
									setUsername(e.target.value);
								}}
							/>
							<label htmlFor="username" className="pointer-events-none absolute left-0 -top-3.5 font-semibold text-gray-300 text-xs transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm">
								New Username
							</label>
							<button onClick={changeUsername} className="w-full mt-4 p-2 bg-blue-500 hover:bg-blue-600 rounded" type="submit" value="submit">
								Change
							</button>
						</div>
					</div>
				</div>
			</>
		);
	}
	return null;
};

export default Account;
