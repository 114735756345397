import React, { useState } from "react";
import { auth } from "../../utils/firebase";
import { GoogleAuthProvider, TwitterAuthProvider, signInWithPopup, signInWithEmailAndPassword } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { AiFillGoogleCircle, AiFillTwitterCircle } from "react-icons/ai";
import { Helmet } from "react-helmet";

export default function Login() {
	// use `loading` with user for any loading state
	const [user] = useAuthState(auth);
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const googleProvider = new GoogleAuthProvider();
	const GoogleLogin = async () => {
		try {
			await signInWithPopup(auth, googleProvider);
		} catch (error) {
			console.error(error.message);
		}
	};

	const twitterProvider = new TwitterAuthProvider();
	const TwitterLogin = async () => {
		try {
			await signInWithPopup(auth, twitterProvider);
		} catch (error) {
			console.error(error);
		}
	};

	const login = async () => {
		try {
			await signInWithEmailAndPassword(auth, email, password);
		} catch (error) {
			console.error(error.message);
		}
	};

	if (user)
		navigate("/dashboard");

	return (
		<>
			<Helmet>
				<title>Crypto Tracker | Login</title>
			</Helmet>
			<div className="mt-24 flex justify-center items-center">
				<div className="w-[90%] lg:w-fit lg:min-w-[400px] flex flex-col gap-4 bg-neutral-800 border border-neutral-700 rounded-xl overflow-hidden">
					<div className="relative h-40 bg-blue-700">
						<svg className="absolute bottom-0 fill-neutral-800" viewBox="0 0 1440 320">
							<path d="M0,64L48,80C96,96,192,128,288,128C384,128,480,96,576,85.3C672,75,768,85,864,122.7C960,160,1056,224,1152,245.3C1248,267,1344,245,1392,234.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
						</svg>
					</div>
					<h1 className="p-1 text-center text-3xl font-bold rounded">
						Welcome back
					</h1>
					<div className="p-4 flex flex-col gap-4 rounded">
						{/* TODO: Make submit buttons work and don't allow submit if required fields aren't filled. */}
						<form
							onSubmit={login}
							className="flex flex-col gap-10"
						>
							<div className="relative">
								<input required
									className="peer w-full py-2 px-1 bg-transparent border-0 border-b-2 leading-tight focus:border-blue-500 focus:ring-0 placeholder-transparent"
									type="email" name="email" id="email" value={email} placeholder="Email"
									onChange={(e) => {
										setEmail(e.target.value);
									}}
								/>
								<label htmlFor="email" className="pointer-events-none absolute left-0 -top-3.5 font-semibold text-gray-300 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm">
									Email
								</label>
							</div>
							<div className="relative">
								<input required
									className="peer w-full py-2 px-1 bg-transparent border-0 border-b-2 leading-tight focus:border-blue-500 focus:ring-0 placeholder-transparent"
									type="password" name="password" id="password" value={password} placeholder="Password"
									onChange={(e) => {
										setPassword(e.target.value);
									}}
								/>
								<label htmlFor="password" className="pointer-events-none absolute left-0 -top-3.5 font-semibold text-gray-300 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-sm">
									Password
								</label>
							</div>
							<button onClick={login} className="p-2 bg-blue-500 hover:bg-blue-600 rounded" type="submit" value="submit">
								Sign in
							</button>
						</form>
						<div className="separator-hr text-sm">
							OR
						</div>
						<div className="flex lg:flex-row flex-col gap-4">
							<button
								type="submit" onClick={GoogleLogin}
								className="p-2 flex flex-row gap-2 justify-center bg-blue-500 hover:bg-blue-600 rounded-md transition-colors"
							>
								<AiFillGoogleCircle className="w-6 h-6"/>
								<p>Sign in with <span className="font-semibold">Google</span></p>
							</button>
							<button
								type="submit" onClick={TwitterLogin}
								className="p-2 flex flex-row gap-2 justify-center bg-blue-500 hover:bg-blue-600 rounded-md transition-colors">
								<AiFillTwitterCircle className="w-6 h-6"/>
								<p>Sign in with <span className="font-semibold">Twitter</span></p>
							</button>
						</div>
						<p className="text-center">
							Don&apos;t have an account? <a className="hyperlink transition-colors" href="/signup">Sign up</a>
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
