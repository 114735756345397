import React from "react";
import CryptoListener from "../components/CryptoListener";
import DashboardHeader from "../components/DashboardHeader";
import Graph from "../components/Graph";
import graphData from "../data/graphData.js";
import { auth } from "../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = () => {
	// use `loading` with user for any loading state
	const [user] = useAuthState(auth);
	const navigate = useNavigate();

	if (!user)
		navigate("/login");

	if (user) {
		return (
			<>
				<Helmet>
					<title>Dashboard</title>
				</Helmet>
				<div className="m-2 grid lg:grid-cols-2 md:grid-cols-1 gap-2">
					<DashboardHeader />
					<div className="hidden md:block bg-neutral-800 border border-neutral-700 rounded-md">
						<div className="m-2 p-2 bg-primary rounded h-64">
							{/* TODO: Grab data from watchlist coins. */}
							<Graph data={graphData}/>
						</div>
					</div>
				</div>
				<div className="m-2 mt-16">
					<h1 className="m-2 text-center text-neutral-400 border-b border-neutral-700">
						Your Watchlist
					</h1>
					<div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
						{/* TODO: Add the ability for user to choose which coins to watchlist. */}
						<CryptoListener id="BTC"/>
						<CryptoListener id="ETH"/>
						<CryptoListener id="SHIB"/>
						<CryptoListener id="BCH"/>
					</div>
				</div>
			</>
		);
	}
	return null;
};

export default Home;
