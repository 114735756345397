import React from "react";
import { ResponsiveLine } from "@nivo/line";

const Graph = ({ data }) => {
	return (
		<>
			<ResponsiveLine
				data={data}
				margin={{ top: 25, right: 25, bottom: 25, left: 35 }}
				xScale={{ type: "point" }}
				xFormat=" >-"
				yScale={{
					type: "linear",
					min: "auto",
					max: "auto",
					stacked: false,
					reverse: false
				}}
				yFormat=" >-.2f"
				curve="catmullRom"
				enableGridX={false}
				enableGridY={false}
				colors={{ scheme: "nivo" }}
				pointSize={1}
				pointColor={{ theme: "background" }}
				pointBorderWidth={10}
				pointBorderColor={{ from: "serieColor", modifiers: [] }}
				pointLabelYOffset={-12}
				enableSlices="x"
				crosshairType="bottom-right"
				useMesh={true}
				legends={[
					{
						anchor: "right",
						direction: "row",
						justify: false,
						translateX: 0,
						translateY: -100,
						itemWidth: 50,
						itemHeight: 20,
						itemsSpacing: 20,
						symbolSize: 10,
						symbolShape: "circle",
						itemDirection: "left-to-right",
						itemTextColor: "#ccc",
						effects: [
							{
								on: "hover",
								style: {
									itemBackground: "rgba(0, 0, 0, .03)",
									itemOpacity: 0.75
								}
							}
						]
					}
				]}
				sliceTooltip={({ slice }) => {
					return (
						<div
							style={{
								background: "#262626",
								padding: "0.5rem 1rem",
								border: "1px solid #404040",
								borderRadius: "0.25rem"
							}}
						>
							<p className="text-center">{slice.name}</p>
							{slice.points.map(p => (
								<div key={p.id}>
									<div className="grid grid-cols-2 gap-1">
										<p style={{ color: p.serieColor }}>
											{p.serieId}
										</p>
										<p>
											{p.data.yFormatted}
										</p>
									</div>
								</div>
							))}
						</div>
					);
				}}
			/>
		</>
	);
};

export default Graph;
